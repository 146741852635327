// Initialize each block on page load (front end).
$(document).ready(function() {
	$('.tds-media-content--media.has-pop-up').each(function(){
		let outer = $(this);
		let inner = $(this).children('.tds-media-content--media--pop-up-click');

		let outer_bounds;
		let inner_bounds;
		let inner_half;

		function get_bounds() {
			outer_bounds = outer[0].getBoundingClientRect();
			inner_bounds = inner[0].getBoundingClientRect();
			inner_half = inner_bounds.width / 2;
			inner_half_neg = inner_half * -1;
		}

		get_bounds();

		$(window).resize(function () { get_bounds(); });
		$(window).scroll(function () { get_bounds(); });

		outer.mousemove(function(e){
			inner.removeClass('static');
			inner.addClass('active');

			var bound_top = e.clientY - outer_bounds.top;
			var bound_bottom = e.clientY - outer_bounds.bottom;
			var bound_left = e.clientX - outer_bounds.left;
			var bound_right = e.clientX - outer_bounds.right;

			var mouse_x = e.clientX - outer_bounds.left-inner_half;
			var mouse_y = e.clientY - outer_bounds.top-inner_half;

			if ( bound_left > 20 && bound_right < -20 ) {
			  inner.css({ 'left' : mouse_x });
			}
			if ( bound_top > 20 && bound_bottom < -20 ) {
			  inner.css({ 'top' : mouse_y });
			}
		});

		outer.mouseout(function(){
			inner.addClass('static');
			inner.removeClass('active');
		});
	});
});